import React from 'react';
import { UIEventHandler } from 'react';
import styled, { ThemeProps, ThemeProvider } from 'styled-components';
import { MIN_WIDTH_FOR_SIDEBAR_NAVIGATION, themeState } from './theme';
import { Theme } from './types';
import { Spacing } from './spacing';

export const ScreenContainer = styled.div`
  background-color: ${(props: ThemeProps<Theme>) => props.theme.background};
`;

const ScreenWrapper = styled.div`
  @media (min-width: ${MIN_WIDTH_FOR_SIDEBAR_NAVIGATION}px) {
    width: 100vw;
    height: 100vh;
  }
  overflow-y: scroll;
`;

const Container = styled.div`
  max-width: 1024px;
  margin: 0 auto;
  padding: ${Spacing.md}px;
  min-height: 90vh;
`;

interface ScreenProps {
  children: React.ReactNode;
  onScroll?: UIEventHandler;
  containerRef?: React.Ref<HTMLDivElement>;
}

export const Screen = ({ children, onScroll, containerRef }: ScreenProps) => {
  return (
    <ThemeProvider theme={themeState.current}>
      <ScreenWrapper onScroll={onScroll} ref={containerRef}>
        <ScreenContainer>
          <Container>{children}</Container>
        </ScreenContainer>
      </ScreenWrapper>
    </ThemeProvider>
  );
};
