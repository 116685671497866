import React from 'react';
import { observer } from 'mobx-react-lite';
import styled, { ThemeProvider } from 'styled-components';
import { themeState } from 'styleguide/theme';
import { DividerLine } from 'styleguide/DividerLine';
import { View } from 'styleguide/View';
import { TextSMMedium } from 'styleguide/Texts';
import { Link } from './Link';

export const Footer = observer(({ showPricingLink }: { showPricingLink: boolean }) => {
  return (
    <ThemeProvider theme={themeState.current}>
      <Container>
        <DividerLine />
        <BottomContainer>
          <View
            style={{
              width: '100%',
              flexDirection: 'row',
              gap: 24,
              textAlign: 'left',
              margin: '4px 0 4px 8px',
            }}
          >
            <Link href="https://www.hevyapp.com/app-terms-conditions">
              <TextSMMedium type="tertiary">Terms &amp; Conditions</TextSMMedium>
            </Link>
            {showPricingLink && (
              <Link href="/pricing">
                <TextSMMedium type="tertiary">Pricing</TextSMMedium>
              </Link>
            )}
            <Link href="https://www.hevyapp.com/privacy-policy">
              <TextSMMedium type="tertiary">Privacy Policy</TextSMMedium>
            </Link>
            <Link href="https://www.hevyapp.com/about-us">
              <TextSMMedium type="tertiary">Contact Us</TextSMMedium>
            </Link>
          </View>
          <View
            style={{
              width: '100%',
              textAlign: 'right',
              margin: '4px 0 4px -8px',
            }}
          >
            <TextSMMedium type="tertiary">
              © 2021 Hevy Studios S.L. All right reserved.
            </TextSMMedium>
          </View>
        </BottomContainer>
      </Container>
    </ThemeProvider>
  );
});

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  justify-content: center;
  height: 51px;
  @media (max-width: 1024px) {
    display: none;
  }
`;

const BottomContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 12px 0;
`;
