import React from 'react';
import { observer } from 'mobx-react-lite';
import styled, { ThemeProvider } from 'styled-components';
import { themeState } from 'styleguide/theme';
import { stores } from 'state/stores';
import { TextMD } from 'styleguide/Texts';
import { ClipLoader } from 'react-spinners';
import { colors } from 'styleguide/colors';

interface Props {
  children: React.ReactNode;
}

const BootstrapContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
`;

export const BootstrapLoadingWrapper = observer(({ children }: Props) => {
  if (!stores.lifecycle.isBootstrapped) {
    return (
      <BootstrapContainer style={{ backgroundColor: themeState.current.background }}>
        <TextMD style={{ marginBottom: 20 }}>Loading...</TextMD>
        <ClipLoader size={30} color={colors.primary500} loading={true} />
      </BootstrapContainer>
    );
  }

  return <ThemeProvider theme={themeState.current}>{children}</ThemeProvider>;
});
