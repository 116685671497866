import React from 'react';
import { CSSProperties } from 'react';
import { ClickableCard } from 'styleguide/ClickableCard';
import { TextMD } from 'styleguide/Texts';
import GoogleLoginImage from 'assets/google-login.svg';
import AppleLoginImage from 'assets/apple-login.svg';
import { View } from 'styleguide/View';
import GoogleLogin, { GoogleLoginResponse, GoogleLoginResponseOffline } from 'react-google-login';

interface LoginWithGoogleButtonProps {
  action: SocialButtonAction;
  onSuccess: (response: GoogleLoginResponse | GoogleLoginResponseOffline) => void;
  onFailure: (error: any) => void;
  style?: CSSProperties;
}

export const LoginWithGoogleButton = ({
  action,
  onSuccess,
  onFailure,
  style,
}: LoginWithGoogleButtonProps) => {
  return (
    <GoogleLogin
      clientId="373742264955-ohb02gdkmda2j3ert36ifurhr2thgd8g.apps.googleusercontent.com"
      scope="email"
      render={renderProps => (
        <SocialLoginButton
          action={action}
          style={style}
          type="google"
          onClick={renderProps.onClick}
        />
      )}
      buttonText="Login"
      onSuccess={onSuccess}
      onFailure={onFailure}
      cookiePolicy={'single_host_origin'}
    />
  );
};

type SocialButtonType = 'google' | 'apple';
type SocialButtonAction = 'login' | 'signup';

interface SocialLoginButtonProps {
  type: SocialButtonType;
  action: SocialButtonAction;
  onClick: () => void;
  style?: CSSProperties;
}

export const SocialLoginButton = ({ type, action, onClick, style }: SocialLoginButtonProps) => {
  const ImageSVG = image(type);

  return (
    <ClickableCard style={style} onClick={onClick}>
      <View
        style={{
          flexDirection: 'row',
          height: '35px',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            paddingLeft: 10,
            height: '30px',
            width: '30px',
            position: 'absolute',
          }}
        >
          <ImageSVG />
        </div>

        <TextMD style={{ flex: 1 }}>{title(type, action)}</TextMD>
      </View>
    </ClickableCard>
  );
};

const image = (type: SocialButtonType) => {
  switch (type) {
    case 'google':
      return GoogleLoginImage;
    case 'apple':
      return AppleLoginImage;
  }
};

const title = (type: SocialButtonType, action: SocialButtonAction) => {
  if (action === 'login') {
    switch (type) {
      case 'google':
        return 'Login with Google';
      case 'apple':
        return 'Login with Apple';
    }
  } else if (action === 'signup') {
    switch (type) {
      case 'google':
        return 'Sign up with Google';
      case 'apple':
        return 'Sign in with Apple';
    }
  }
};
